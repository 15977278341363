<template>
  <addField
    :path="buttonAttrs['uri']"
    :additionalAttributes="{ n_id: buttonAttrs['n_id'],n_id_key: buttonAttrs['n_id_key'], function: 'getInsertedFieldsEdit' ,'updateTable':true}"
  >
    <primaryButton label="Edit" :icon="'mdi-square-edit-outline'" type="button" color="save" elevation="0"></primaryButton>
  </addField>
  <!-- <button class="submit editIcon" type="submit" value="Edit" onclick="AddField({$buttonAttrs["n_id"]},'{sha1 ($hashKey)}','{$buttonAttrs["uri"]}',{ 'updateTable':'false' });return false;"><span class="buttLabelWithIcon">Edit</span></button> -->
</template>
<script>
import addField from "@/commonComponents/addField.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  components: { primaryButton, addField },
  props: ["buttonAttrs"],
};
</script>